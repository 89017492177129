<template functional>
  <p 
    class="privacy-policy" 
    :class="props.classNameProp"
  >
    <slot />
  </p>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  functional: true,
  props: {
    classNameProp: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.privacy-policy {
    position: absolute;
    top: 55px;
    font-size: 12px;
    color: $color-grey-2;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    line-height: 18px;

    @media(min-width: $md-width) {
          width: 570px;
    }

}
</style>
