<template>
  <div class="fade-in cdc-page" :class="{'cdc-page-money-test-variant': isMoneyTest}">
    <QuestionTitle 
      class="cdc-page__title"
      :title="'What are your contact details?'" 
      :subtitle="'Our experts have helped over 10,000 businesses like yours.'"
      :title-color-class="titleColor"
    />
    <div class="cdc-container">
      <div 
        class="input-container" 
        :class="{ active: name }"
      >
        <span v-if="isNameValid"> Your full name </span>
        <span v-else class="red"> Please enter your full name </span>
        <input
          v-model.trim="name"
          class="form-control"
          name="Name"
          type="text"
          placeholder="Your full name"
          :class="{ red: !isNameValid }"
          @input="input"
        >
      </div>
        
      <div class="input-container" :class="{ active: phone }">
        <span v-if="isPhoneValid"> Contact number </span>
        <span v-else class="red"> Please enter a valid phone number </span>
        <input
          v-model.trim="phone"
          class="form-control"
          :class="{ red: !isPhoneValid }"
          name="Phone"
          type="text" 
          inputmode="tel"
          placeholder="Contact number"
          @input="input"
        >
      </div>

      <div class="input-container" :class="{ active: email }">
        <span v-if="isEmailValid">Email address</span>
        <span v-else class="red"> Please enter a valid email address </span>
        <input
          v-model.trim="email"
          class="form-control"
          :class="{ red: !isEmailValid }"
          name="Email"
          type="email"
          placeholder="Email address"
          @input="input"
        >
      </div>

      <div class="next-button-wrapper">
        <Button
          :class-prop="buttonBg"
          :text="buttonText"
          :right-arrow="buttonRightArrow"
          :right-arrow-color="rightArrowColor"
          :button-rounded-lg="buttonRoundedLg"
          class="next-btn join-call-btn"
          :disabled="!isValid"
          :is-working="isWorking"
          :is-squared-button="true"
          :hover-state="true"
          @click="next"
        />
      </div>

      <div class="trustpilot-wrapper">
        <TrustPilot />
        <PrivacyPolicy>
          You can find full details of how your data will be used by Think in our 
          <a href="https://thinkbusinessloans.com/privacy-policy/" target="_blank">
            <span class="font-weight-bold">Privacy Policy</span>
          </a>. 
          By pressing {{ buttonText }}, you confirm you agree to have read and understood our
          <a href="https://thinkbusinessloans.com/terms" target="_blank">
            <span class="font-weight-bold">website Terms and Conditions</span>
          </a>.
        </PrivacyPolicy>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from '@/components/shared/Button'
import PrivacyPolicy from '@/components/shared/PrivacyPolicy'
import TrustPilot from './TrustPilot'
import { fullNameRegex, emailRegex, phoneRegex } from '@/helpers/constants/regex-patterns'
import instrument from '@/helpers/instrument'
import QuestionTitle from '@/components/question/QuestionTitle'

export default {
  name: 'JoinCall',
  components: { 
    Button, 
    TrustPilot, 
    PrivacyPolicy,
    QuestionTitle
  },

  props: {
    value: {
      type: Object,
      required: true
    },
    rightArrowColor: {
      type: String,
      default: ''
    },
    buttonRoundedLg: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      buttonRightArrow: true,
      isWorking: false,
      email: '',
      phone: '',
      name: '',
      buttonText: 'Next'
    }
  },

  computed: {
    ...mapGetters(['buttonBg', 'getJourney', 'isMoneyTest']),
    isValid () {
      return (
        this.isNameValid &&
        this.isPhoneValid &&
        this.isEmailValid
      )
    },
    titleColor () {
      return this.isMoneyTest ? 'money-partner__color-2' : ''
    },
    isPhoneValid () {
      return Boolean(this.phone) && Boolean(this.phone.match(phoneRegex))
    },
    isEmailValid () {
      return Boolean(this.email) && Boolean(this.email.match(emailRegex))
    },
    isNameValid () {
      return (
        Boolean(this.name) &&
        Boolean(this.name.match(fullNameRegex)) &&
        Boolean(this.name.length >= 5)
      )
    }
  },

  methods: {
    next () {
      instrument.submitForm()
      this.buttonRightArrow = false
      this.isWorking = true
      // delay before we go to next screen
      // pretend load results
      setTimeout(() => { 
        this.$emit('next')
      }, 2500)
    },
    input () {
      let result = {
        email: this.email,
        telephoneNumber: this.phone,
        name: this.name
      }
      this.$emit('input', result)
    }
  }
}
</script>

<style lang="scss">
  .cdc-container {
    .button {
      @media (max-width: $xs-width) {
          width: 100%
      }
      &.working {
        width:220px;
      }
    }
  }
</style>

<style lang="scss" scoped>
.cdc-page {
  &__title {
      padding-left: 15px;
      padding-right: 15px;
  }

}
.cdc-container {
  padding: 0 16px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

input {
  width: 100%;
  height: 100%;
}

.next-button-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;
  margin-right: 8px;

  @media (min-width: $tablet-min) {
   width: 360px;
  }

  @media (min-width: $tablet-max) {
   width: 540px;
  }

}

.next-btn {
    margin-top: 5vh;
}

.input-container {
  height: 56px;
  width: 100%;
  position: relative;
  margin: 6px 20px 6px 20px;
  padding: 7px 16px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: unset;
  border: 1px solid #E5E5E5;

  @media (min-width: $tablet-min) {
    width: 348px;
    max-width: 348px;
  }

  ::placeholder {
    font-size: 14px;
  }

  span {
    position: absolute;
    top: 5px;
    font-size: 12px;
    display: none;
    color: #152D72;

  }

  &.active {
    border: 1px solid #152D72;

    span {
      display: block;
    }

  input {
    padding: 0;
    font-weight: normal;
    background: transparent;
    border: 0;
    border-radius: 0;
    outline: 0;
    box-shadow: none;
    padding-top: 16px;
    font-size: 14px;
    
    @media (max-width: $mobile-breakpoint-max) {
      font-size: 16px;
      }
    }
  }

  display: flex;

  input {
    font-weight: normal;
    background: transparent;
    border: 0;
    border-radius: 0;
    outline: 0;
    box-shadow: none;
    padding: 0;
    font-size: 14px;

    @media (max-width: $mobile-breakpoint-max) {
     font-size: 16px;
    }
  }

}

.red {
  color: red !important;
}

.trustpilot-wrapper {
  padding: 24px 0 0 0;
  width: 100%;
  position: relative;
}

.cdc-page-money-test-variant {
  .input-container.active {
     border: 1px solid $purple-1;
  }
}
</style>
