<template>
  <div :class="`${getPartner.name}`">
    <NavbarJourney :telephone="getPhone" :friendly-telephone="getFriendlyPhone" :telephone-color="dynamicColor" />
    <div class="container">
      <div>
        <JoinCall 
          v-model="value" 
          :right-arrow-color="dynamicColor" 
          :button-rounded-lg="isMoneyTest"
          @next="next"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import JoinCall from '@/components/results/JoinCall'
import turnOverRoutes from '@/helpers/turnover/turnover-routes'
import NavbarJourney from '@/components/shared/NavbarJourney'
import { ASSETFINANCE } from '@/helpers/constants/partner-names'
export default {
  name: 'ResultsPage',

  components: {
    NavbarJourney,
    JoinCall
  },

  data () {
    return {
      value: {},
      ASSETFINANCE
    }
  },

  computed: {
    ...mapGetters(['getPhone', 'getFriendlyPhone', 'getPartner', 'isMoneyTest', 'dynamicColor']),
    turnOverAmount () {
      return this.$store.getters.getAnswerById('qid_turnover')
    }
  },
  methods: {
    async join () {
      this.$store.commit('SET_IS_WORKING', true)
      this.$store.commit('LOAD_ADDRESS_SUCCESS', { ...this.$store.getters.address, Company: this.$store.getters.name })
      this.$store.dispatch('actionUpdateCallBackData', { ...this.value, businessName: this.$store.getters.name })

      await this.$store.dispatch('actionSubmit', this.$store.state.CallBack)
      // asset finance route
      if (this.getPartner && this.getPartner.name === this.ASSETFINANCE) {
        this.$router.push({ name: 'asset-confirmation' }) 
      } else {
        let turnover
        if (localStorage.getItem('turnover')) {
          turnover = Number(localStorage.getItem('turnover'))
        }
        // decide which route based on turnover
        const routerName = turnOverRoutes(turnover, 'confirmation')
        this.$router.push({ name: routerName }) 
      }
    },
    next () {
      return this.join()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
  width: 100%;
  margin: 32px auto 0;
}

@media (max-width: $mobile-breakpoint-max) {
  .container {
    padding: 0;
    margin: 8px auto 0;
  }
}
</style>
